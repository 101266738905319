
import {Component, Vue} from 'vue-facing-decorator';
import {closeWidget} from "@/utils";

@Component
export default class Success extends Vue {
  close = closeWidget

  isSBP(){
    return this.$route.query['url']?.includes('qr.nspk.ru') ? true : false
  }

  openSBPWidget(){

    // eslint-disable-next-line
    /* eslint-disable */
    //const SlideUpWidget = require('/public/js/slide-up-widget')

    //const slideUpWidget = new SlideUpWidget(window.localStorage, window.navigator) // eslint-disable-line

    const url = () => this.$route.query['url']

    window['sbpWidget'].showBankSelector(url())


  }

}
