
import {Component, Prop, Vue} from 'vue-facing-decorator';
import * as API from "@/api";
import {EntryTicket, EventData, FREventResult, Seat, SegmentsDiscounts} from "@/@types/api";
import SvgPanZoom from "svg-pan-zoom";
import {closeWidget, redirect} from "@/utils";
import Point = SvgPanZoom.Point;
import {tr} from "vuetify/locale";
import {getPromocodeData} from "@/api";
// import {RTDB} from "@/firebase";

type ValidateRules = Array<(value: any) => any>

@Component({
  components: {}
})
export default class TCEventMap extends Vue {

  closeDialog = false

  close = closeWidget

  //selectedLimit = 10

  payUrl = ''

  primaryColor = 'blue-lighten-1'

  dialogEntryTickets = false // если со схемы кликнули на входной сегмент - показать диалог выбора
  dialogEntryTicketsSegment = '' // если указан, фильтруем

  dialogPromo = false
  dialogPromoPending = false
  promoCode = ''
  promoCodeError = ''
  segmentsDiscounts: SegmentsDiscounts | null = null

  getSelectedSegments(): number[] {
    const segments: Set<number> = new Set
    this.selected.entry.forEach(v => segments.add(v.segmentId))
    this.selected.seats.forEach(seatId => {
      if (this.eventData?.seats) segments.add(this.eventData?.seats[seatId].segmentId)
    })
    return Array.from(segments)
  }

  async applyPromoCode() {

    this.promoCode = this.promoCode.trim()

    this.dialogPromoPending = true

    API.getPromocodeData(this.eventId, this.promoCode, this.getSelectedSegments())
        .then(discount => {

          if (discount) {
            this.segmentsDiscounts = discount
            this.promoCodeError = ''
            this.dialogPromo = false
          } else
            this.promoCodeError = 'Промокод недействительный'

        })

        .catch((e) => {
          this.promoCodeError = 'Не удалось, попробуйте позже'
          console.log(e)
        })

        .finally(() => this.dialogPromoPending = false)

  }


  showEntrySegmentDialog(segmentId = '') {

    this.dialogEntryTickets = true

    if (this.eventData && this.eventData.entryTickets)
      this.dialogEntryTicketsSegment = segmentId
  }

  async createOrder(sbp: boolean) {

    this.step = 4

    API.createOnlineOrder({
      eventId: this.eventId,
      seats: this.selected.seats,
      entryTickets: this.selected.entry,
      name: this.client.name,
      tel: this.client.tel.trim() ? "7" + this.client.tel.trim() : "",
      email: this.client.email,
      birthDate: this.client.birthDate,
      sum: this.getTotalSum(),
      promoCode: this.promoCode,
      comment: ""
    }, sbp)
        .then(orderData => {

          // тут надо передавать заказ наружу, что бы скрипт виджета его отправил на сервер при закрытии
          window.parent.postMessage(`ticketcafe.orderNumber=${orderData.orderNumber}`, '*')
          window.parent.postMessage(`ticketcafe.orderId=${orderData.orderId}`, '*')

          this.payUrl = orderData.payUrl
          if (this.payUrl == "success") {
            //this.$router.push('/success')
            this.step = 6
          } else {
            this.step = 5

            // Если режим админа тогда показываем QR
            // if (this.$route.query['admin'])
            //   this.$router.push(`/qr?url=${encodeURIComponent(orderData.payUrl)}`)
            // // Если клиент, тогда перенаправляем на процессинг
            // else
            setTimeout(() => redirect(orderData.payUrl), 100)

          }
          //this.step = 6

        })

        .catch((e) => {

          console.log(e)

          this.$router.push('/fail?text=' + encodeURIComponent('Нам не удалось сформировать Ваш заказ. Пожалуйста повторите попытку.'))//this.step = 7

        })


  }

  windowReload() {
    window.parent.location.reload()
  }

  dialogSellerInfo = false

  getSelectedSeatsData(): Seat[] {
    return Object.values(this.eventData?.seats ?? {}).filter(s => this.selected.seats.includes(s.id))
  }

  getSegmentDiscountK(segmentId: number): number {
    return this.segmentsDiscounts && this.segmentsDiscounts[segmentId] ? (100 - this.segmentsDiscounts[segmentId]) * 0.01 : 1
  }

  getTotalSum() {

    let sum = 0

    this.getSelectedSeatsData().forEach(seat =>
        sum += Math.round(seat.price * this.getSegmentDiscountK(seat.segmentId))
    )

    this.selected.entry.forEach(entry =>
        sum += Math.round(entry.price * this.getSegmentDiscountK(entry.segmentId))
    )

    return sum
  }

  getTotalCount() {
    return this.getSelectedSeatsData().length + this.selected.entry.length
  }

  onInputBirthDate(event: InputEvent) {

    // прибавили символ
    if (event.data) {
      if (this.client.birthDate.length === 2 || this.client.birthDate.length === 5)
        this.client.birthDate += '.'
      else if (this.client.birthDate.length > 10)
        this.client.birthDate = this.client.birthDate.slice(0, 10)
    }
    // // удалили символ
    else
      this.client.birthDate = ''

  }

  async onSubmitClientForm() {

    const {valid} = await (this.$refs.clientForm as any).validate()

    if (valid) //  || location.toString().includes('localhost')
      this.step = 3

  }

  client = {
    name: '',
    email: '',
    tel: '',
    birthDate: ''
  }

  nameRules: ValidateRules = []

  emailRules: ValidateRules = []

  telRules: ValidateRules = []

  dateRules: ValidateRules = []

  @Prop
  eventId = ''

  svgHtml = ''

  isMapPanned = false

  step = 1

  hoveredCardSeatId: number | undefined

  selected: {
    seats: number[]
    entry: { id: number, segmentId: number, segmentName: string, price: number }[]
  } = {
    seats: [],
    entry: []
  }

  seatOver: Seat | null = null

  seatTooltipStyle = {
    top: '',
    left: ''
  }

  eventData: EventData | null | undefined = undefined

  pricesValues: number[] = []
  pricesColors: string[] = []

  updatePricesColors() {
    // svg должна быть уже в DOM что бы считать цвета цен

    this.pricesColors = []
    for (let i = 1; i <= this.pricesValues.length; i++) {
      const element = document.getElementsByClassName('price-' + i)[0]
      this.pricesColors.push(element ? window.getComputedStyle(element).getPropertyValue('fill') : '')
    }
  }

  onHoverSelected(seatId: number) {
    this.hoveredCardSeatId = seatId
    document.getElementById(seatId.toString())?.classList.add('seat_hover')
  }

  onPriceHover(price) {
    // this.eventData?.seats?.forEach(seat => {
    //   if (seat.price === price)
    //     document.getElementById(seat.seatId.toString())?.classList.add('seat_hover')
    // })
  }

  onPriceLeave(price) {
    // this.eventData?.seats?.forEach(seat => {
    //   //if (seat.price === price) {
    //   document.getElementById(seat.seatId.toString())?.classList.remove('seat_hover')
    //   // }
    //
    // })
  }

  onLeaveSelected() {
    if (this.hoveredCardSeatId)
      document.getElementById(this.hoveredCardSeatId.toString())?.classList.remove('seat_hover')
    this.hoveredCardSeatId = undefined
  }

  // для определения когда в dom загрузился maps
  firstUpdate = true

  updated() {

    // Это инициализация когда SVG загрузился - перенес в коллбек loadSVG
    // if (this.firstUpdate && document.getElementById('svgMapElement')) {
    //   this.firstUpdate = false
    //   this.initPanZoom()
    //   this.renderSeats()
    // }

  }

  renderSeats() {
    if (!this.svgMapElement || !this.eventData) {
      console.error(new Error('Схема не готова или нет данных'))
      return;
    }

    //const elements = Array.from(this.svgMapElement.getElementsByClassName('seat') as HTMLCollectionOf<HTMLElement>) //as HTMLElement[])
    if (this.eventData.seats) {
      const elementsSeats = this.svgMapElement.querySelectorAll<HTMLElement>('.seat')
      for (const element of elementsSeats) {

        // удаляем стиль ценовой группы
        element.classList.forEach(name => {
          if (name.includes('price-')) element.classList.remove(name)
        })

        const seat = this.eventData.seats[element.id]

        //console.log('seat ',element.id, !!seat)

        if (seat) {

          element.classList.add('seat_free');

          // element.style.fill = this.colorPalette.get(seat.price) ?? 'yellow';
          // element.style.stroke = this.colorPalette.get(seat.price) ?? 'yellow';
          element.classList.add('price-' + (this.pricesValues.indexOf(seat.price) + 1))

        }
        // Место не в продаже. Оставляем только класс seat
        else {
          element.classList.remove('seat_free')

          // element.style.removeProperty('fill')
          // element.style.removeProperty('stroke')

          // если место было выбрано - снимаем

          this.removeSeatFromSelected(Number(element.id))

        }

      }
    }

    if (this.eventData.entryTickets) {

      // Раскрашиваем сегмент в цвет цены
      const elementsSegment = this.svgMapElement.querySelectorAll<HTMLElement>('.entryTicketsAll') // костыль для Айрата, надо заменить на segment
      for (const element of elementsSegment) {

        // удаляем стиль ценовой группы
        element.classList.forEach(name => {
          if (name.includes('price-')) element.classList.remove(name)
        })

        const entryTicket = this.eventData.entryTickets.find((v) => v.segmentId == parseInt(element.id))
        if (entryTicket)
          element.classList.add('price-' + (this.pricesValues.indexOf(entryTicket.price) + 1))

      }


    }

    // Object.values(this.eventData.seats)?.forEach(seat => {
    //
    //   const element = document.getElementById(seat.id.toString())
    //
    //   if (element) {
    //     element.classList.add('seat_free');
    //     element.style.fill = this.colorPalette.get(seat.price) ?? 'white';
    //     element.style.stroke = this.colorPalette.get(seat.price) ?? 'white';
    //
    //   } else {
    //     // в 1с с запасом заведено, поэтому часто срабатывает
    //     //console.error(new Error('не найден элемент места по id '+seat.seatId))
    //     return;
    //   }
    //
    // })

  }

  zoom(zoomIn: boolean) {

    if (this.svgMapElement)
      if (zoomIn)
        SvgPanZoom(this.svgMapElement).zoomIn()
      else
        SvgPanZoom(this.svgMapElement).zoomOut()

  }

  formatMoney(amount, digits = 0) {
    // Преобразуем число в строку и разделяем целую и десятичную части
    let parts = amount.toFixed(digits).toString().split('.');

    // Форматируем целую часть, добавляя разделители тысяч
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '\u00A0');

    // Возвращаем отформатированную строку
    return parts.join('.') + '\xa0₽';
  }

  svgMapElement: HTMLElement | null = null
  svgPanZoom: SvgPanZoom.Instance | null = null

  initPanZoom() {

    const initSvgTooltip = (svgMapElement) => {

      // Функция для обработки события наведения на элемент
      const showTooltip = (event) => {

        const svgElement = event.target as HTMLElement
        //svgElement.classList.add('seat_over')

        // Позиция подсказки рядом с элементом
        const svgRect = svgElement.getBoundingClientRect()
        this.seatTooltipStyle.top = svgRect.top + 15 + 'px'
        this.seatTooltipStyle.left = svgRect.left + svgRect.width + 15 + 'px'

        // Задаем место на которое навели - подсказка появится
        //this.seatOver = this.eventData?.seats?.find(v => v.seatId === Number(svgElement.id))
        this.seatOver = (this.eventData?.seats || {})[svgElement.id]

      }

      // Функция для обработки события ухода курсора с элемента
      const hideTooltip = (event) => {
        //const svgElement = event.target as HTMLElement
        //svgElement.classList.remove('seat_over')
        this.seatOver = null
        //tooltip.style.display = 'none'
      }

      const svgElements = svgMapElement.getElementsByClassName('seat')
      for (let i = 0; i < svgElements.length; i++) {
        const svgElement = svgElements[i]
        svgElement.addEventListener('mouseenter', showTooltip)
        svgElement.addEventListener('mouseleave', hideTooltip)
      }

    }

    this.svgMapElement = document.getElementById('svgMapElement')

    if (this.svgMapElement) {

      this.svgPanZoom = SvgPanZoom(this.svgMapElement, {
        // viewportSelector: '.seatScheme',
        mouseWheelZoomEnabled: false,
        dblClickZoomEnabled: false,
        center: true,
        fit: true,
        controlIconsEnabled: false,
        zoomScaleSensitivity: 1,
        maxZoom: parseInt(this.svgMapElement.getAttribute('tc-max-zoom') ?? '3'),
        minZoom: 1,
        panEnabled: true,
        preventMouseEventsDefault: true,
        // beforePan: this.beforePan.bind(this),

        //onPan: this.onPan.bind(this),
      })

      initSvgTooltip(this.svgMapElement)
    }

  }

  // lastPanPoint = {x: 0, y: 0}
  // beforePan(point){
  //   console.log(point)
  //   this.lastPanPoint = point
  // }

  async loadSVG(name: string) {

    // todo Учесть тут может быть исключение!

    const response = await fetch(`maps/${name}.svg`)

    if (response.ok) {
      this.svgHtml = await response.text()
      // рендер сработает в событии update this.renderSeats()

    } else {
      console.error(response)
      this.$router.push('/fail')
    }

  }

  async created() {

    this.telRules.push(value => {

      if (this.eventData?.requireTel && !value.trim()) return false;
      if (value.trim() && !/^\d+$/.test(value)) return 'Некорректный формат'

      return true

    })
    this.dateRules.push(value => {

      //if (/^\d{11}$/.test(value)) return true
      //this.client.error = !value// !/^\d+$/.test(value)

      if (this.eventData?.requireBirthDate && !value.trim()) return false;

      // 25.12.2023
      if (value.trim() && !value.match(/^(0[1-9]|[12][0-9]|3[01])\.(0[0-9]|1[0-2])\.\d{4}$/)) return 'Некорректная дата'

      return true
    },)

    this.emailRules.push(value => {
      if (!value.trim()) return false;

      if (!/.+@.+\..+/.test(value))
        return 'E-mail некорректный'

      return true
    },)

    this.nameRules.push(value => {
      if (!value.trim()) return false;
      if (!/^[a-zA-Zа-яА-Я\s']+$/.test(value)) return 'Неверные символы'
      if (value?.length > 32) return 'Имя слишком длинное'

      return true
    })


    if (!this.eventId) {
      console.error('eventId is undefined on created hook')
      return;
    }

    const isCssColor = (strColor) => {
      const s = new Option().style;
      s.color = strColor;
      return s.color !== '';
    }

    const color = this.$route.query['color']
    if (color && typeof color === 'string')
      this.primaryColor = isCssColor('#' + color) ? '#' + color : color

    // Вариант прямой

    this.eventData = await API.getEvent(this.eventId)

    if (!this.eventData) {
      console.error('eventData отсутствует по этому мероприятию')
      this.$router.push('/fail?text=' + encodeURIComponent('Мероприятие не найдено или уже прошло'))
      return;
    }

    this.pricesValues = [
      ...new Set(Object.values(this.eventData?.seats ?? {}).map(seat => seat.price).sort((a, b) => b - a)),
      ...new Set(Object.values(this.eventData?.entryTickets ?? {}).map(entry => entry.price).sort((a, b) => b - a)),
    ]

    if (this.eventData?.svgMapName) {

      //if (this.eventData.svgMapName === 'https://web.ticketcafe.ru/static/backstage01_2.svg')
      //this.eventData.svgMapName = 'backstage'
      if (this.eventData.svgMapName === 'https://web.ticketcafe.ru/static/2.svg')
        this.eventData.svgMapName = 'shore'

      this.loadSVG(this.eventData.svgMapName)
          .then(() => {

            this.firstUpdate = false
            this.initPanZoom()
            this.renderSeats()

            this.updatePricesColors()

          })
          .catch(e => console.error(e))
    } else {
      // Без схемы виджет должен работать и выводить входные билеты. Если и их нет - пишет что билетов нет извините
      //console.error('svgMapName не определена')
      //this.$router.push('/fail')
      //return;
    }

    // Вариант через FR но как его своевременно обновлять?
    //let firstResponse = true;
    // RTDB.onValue(RTDB.getRef(`/events/${this.eventId}`), async (snap) => {
    //   const result = snap.val() as FREventResult | undefined
    //
    //   // if (!result?.data || !result?.timestamp) {
    //   //   API.getEvent(this.eventId)
    //   //   return;
    //   // }
    //
    //   // ПЕРВОЕ ПОЛУЧЕНИЕ ДАННЫХ
    //   if (firstResponse) {
    //     firstResponse = false
    //
    //     if (result?.data) {
    //       this.eventData = result.data
    //
    //       const cacheTimeout = ~~(Date.now() * 0.001) - result.timestamp
    //       // console.log(~~(Date.now() * 0.001))
    //       // console.log(result.timestamp)
    //       console.log('EventData FR cache timeout:', cacheTimeout)
    //
    //       // todo если схема очень протухла а прямой запрос не удался - что делать?
    //
    //       if (cacheTimeout > 60 * 2) {
    //         console.log('Old cache, call api...')
    //         API.getEvent(this.eventId)
    //             .catch(e => console.error(e))
    //       }
    //
    //     } else
    //       this.eventData = await API.getEvent(this.eventId)
    //
    //     // Данные не получены не из FR не из Сервера
    //     if (!this.eventData) {
    //       console.error('eventData отсутствует по этому мероприятию')
    //       this.$router.push('fail')
    //       return;
    //     }
    //
    //     if (this.eventData?.svgMapName)
    //       this.loadSVG(this.eventData.svgMapName)
    //           .catch(e => console.error(e))
    //     else {
    //       console.error('svgMapName не определена')
    //       this.$router.push('fail')
    //       return;
    //     }
    //
    //
    //   } else {
    //
    //     if (result?.data) {
    //       this.eventData = result.data
    //       this.renderSeats()
    //     } else {
    //       console.error('Получены пустые данные event')
    //       this.$router.push('fail')
    //       return;
    //     }
    //
    //   }
    //
    //
    // })

  }

  async mounted() {

    // this.svgHtml - будет undefined, получение определяем в update()

    window.addEventListener('message', (event) => {
      //console.log('from parent:', event.data)
      if (event.data === 'ticketcafe.close')
        this.closeDialog = true
    });

    window.parent.postMessage('ticketcafe.API_URL=' + API.API_URL, '*')

  }

  addSeatToSelected(id: number) {
    if (this.getTotalCount() >= this.getTicketsLimit()) return;

    this.segmentsDiscounts = null // сбрасываем промокод

    this.selected.seats.push(id)

    const element = document.getElementById(id.toString())
    if (element) {
      //element.classList.remove("seat_free")
      element.classList.add("seat_selected")
    }

    const elementRow = document.getElementById(id.toString().substring(0, 6))
    if (elementRow) {
      //element.classList.remove("seat_free")
      elementRow.classList.add("row_selected")
    }

    this.selected.seats.reverse()

    //this.selectedSeats = [...this.selectedSeats]
  }

  addEntryToSelected(entry: EntryTicket) {

    this.segmentsDiscounts = null // сбрасываем промокод

    if (
        this.getTotalCount() < this.getTicketsLimit()
        && (entry.countAvailable - this.getEntrySelectedCount(entry.segmentId, entry.price)) > 0
    )
      this.selected.entry.push({
        id: entry.id, segmentId: entry.segmentId, segmentName: entry.segmentName, price: entry.price
      })
  }

  getTicketsLimit() {
    return this.eventData?.ticketsLimit || 100
  }

  delEntryToSelected(id: number) {
    const element = this.selected.entry.find(entry => entry.id === id)
    if (element != undefined)
      this.selected.entry.splice(this.selected.entry.indexOf(element), 1)
  }

  getEntrySelectedCount(segmentId: number, price: number) {
    return this.selected.entry.filter(entry => entry.segmentId === segmentId && entry.price === price).length
  }

  removeSeatFromSelected(id: number) {

    if (this.selected.seats.includes(id))
      this.selected.seats.splice(this.selected.seats.indexOf(id), 1)

    const element = document.getElementById(id.toString())
    if (element) {
      //element.classList.add("seat_free") // todo когда схема будет обновляться в онлайн, тут проверять может не свободно будет
      element.classList.remove("seat_selected")
      element.classList.remove("seat_hover")
    }

  }

  panStartPoint: Point | null | undefined = null

  onSvgMapTouchStart() {
    this.isMapPanned = true
    this.panStartPoint = this.svgPanZoom?.getPan()
  }

  onSvgMapMouseDown(event: MouseEvent) {
    this.isMapPanned = true
    this.panStartPoint = this.svgPanZoom?.getPan()

    event.preventDefault()
    event.stopPropagation()

    return false

  }

  // в onSvgMapClick это делаем
  // onSvgMapTouchEnd(){
  //   console.log('onSvgMapTouchEnd')
  //   this.isMapPanned = false
  //
  // }

  onSvgMapMouseUp() {
    this.isMapPanned = false
  }

  onSvgMapClick(event: MouseEvent) {

    if (!this.svgMapElement || !this.eventData?.seats) return;

    // ВАЖНО! Нужно отсеять события перетаскивания, убедиться что это был клик по точке, а не захват что бы двигать схему!
    if (JSON.stringify(this.panStartPoint) != JSON.stringify(this.svgPanZoom?.getPan()))
      return;

    // // Если первый уровень - приближаем
    // if (this.svgPanZoom?.getZoom() === 1) {
    //   console.log(event)
    //   this.svgPanZoom.zoom(2)
    //   this.svgPanZoom.enablePan()
    //   //this.svgPanZoom.pan({})
    //   return;
    // }


    this.isMapPanned = false

    // // Для мобилой всегда увеличивает если схема мелка
    // if (this.$vuetify.display.mobile && SvgPanZoom(this.svgMapElement).getZoom() <= 1.1) {
    //   this.zoom(true)
    //   return;
    // }

    const target = event.target as Element

    // Если элемент место
    if (target.classList.contains('seat')) {

      //const seat = this.eventData?.seats?.find(seat => seat.id === Number(event.target.id))
      const seat = this.eventData.seats[target.id ?? '']

      // Если место в продаже (свободно)
      if (seat)
          // Если не выбрано -> выбираем
        if (!this.selected.seats.includes(seat.id))
          this.addSeatToSelected(seat.id)
        else
          this.removeSeatFromSelected(seat.id)

      this.$forceUpdate()
    }

    // Если кликнули по сегменту - ложные срабатывания!
    else if (target.classList.contains('entryTicketsAll')) {

      //this.showEntrySegmentDialog(event.target.id)
      this.showEntrySegmentDialog()

    }


  }

  toStep1() {

    this.step = 1

  }

  toStep2() {
    this.step = 2
  }

}

