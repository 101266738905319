import axios, {AxiosResponse} from "axios";
import {CreateOrderData, EventData, OrderData, Seat, SegmentsDiscounts} from "@/@types/api";

function getFireUrl(apiUrl: string) {
    return apiUrl
        .replace('https://', '')
        .replace('http://', '')
        .replace('api.ticketcafe.ru:449/', '')
        .replace('api.ticketcafe.ru/', '')
        .replace(/[/]/g, '-')
        .replace(/[.!:]/g, '')
}

//const ROOT_URL = 'https://api.ticketcafe.ru/widget2/hs/widget/'
//export const API_URL = 'https://api.ticketcafe.ru:449/widget2.dev/hs/widget'
export const API_URL = process.env.VUE_APP_API_URL ?? ''
export const FIRE_URL = getFireUrl(API_URL)

//const authConfig = {}//{auth: {username: 'backstage', password: '55Fk9843756Bd'}}

// todo gzip apache включить

export async function getEvent_old(eventId: string): Promise<EventData | undefined> {
    // if (!eventId) return undefined;
    //return (await axios.get(`${API_URL}/events/${eventId}`)).data.data

    return new Promise((resolve, reject) => {
            if (!eventId)
                reject(undefined)
            else
                axios.get(`${API_URL}/events/${eventId}`)
                    .then(result => {
                        resolve(result.data.data)
                    })
                    .catch(err => {
                        reject(err)
                    })
        }
    )

}


export async function getEvent(eventId: string): Promise<EventData | null> {
    // if (!eventId) return undefined;
    //return (await axios.get(`${API_URL}/events/${eventId}`)).data.data

    try {
        return (await axios.get(`${API_URL}/events/${eventId}`)).data.data
    } catch (e) {
        return null
    }

}

// export async function createOrder(data: CreateOrderData): Promise<OrderData> {
//
//     const result = (await axios.post(`${API_URL}/online-orders`, data)).data.data as OrderData | undefined
//     if (result?.payUrl?.includes('http'))
//         return result
//     else
//         throw Error('Invalid createOrder result')
// }


export async function createOnlineOrder(data: CreateOrderData, sbp = false): Promise<OrderData> {

    return new Promise((resolve, reject) => {
            axios.post(`${API_URL}/online-orders?sbp=${sbp}`, data)
                .then(result => {
                    resolve(result.data.data)
                })
                .catch(err => {
                    reject(err)
                })
        }
    )

}

// export function cancelOrder(orderId) {
//      //axios.get(`${ROOT_URL}order_cancel`, orderId)
// }


export async function getPromocodeData(eventId: string, promocode: string, segments: number[]): Promise<SegmentsDiscounts> {
    return new Promise((resolve, reject) => {
            axios.get(`${API_URL}/promocode/${eventId}/${promocode}`,
                {
                    params: {segments: segments.join(',')}
                })
                .then(result => {
                    // от 1с придет с кодом сегмента строковым
                    <{[segmentId: string]: number}>result.data.data

                    const segmentDiscounts: SegmentsDiscounts = new Map
                    for (const key in result.data.data)
                        segmentDiscounts.set(Number(key), result.data.data[key])

                    resolve(result.data.data)
                })
                .catch(err => {
                    reject(err)
                })
        }
    )

}
