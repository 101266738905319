import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Map = _resolveComponent("Map")!

  return (_ctx.eventId )
    ? (_openBlock(), _createBlock(_component_Map, {
        key: 0,
        "event-id": _ctx.eventId
      }, null, 8, ["event-id"]))
    : _createCommentVNode("", true)
}