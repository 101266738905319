export function redirect(url) {
    //window.location.href = url

    window.open(url, '_blank');

}


export function closeWidget() {
    //console.log('to parent:', 'ticketcafe.close')
    //console.log('window.origin', window.origin)
    // К паренту нельзя обращаться, будет исключение
    //console.log('window.parent.origin', window.parent.origin)
    window.parent?.postMessage('ticketcafe.close','*')
}
