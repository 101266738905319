

import SvgMap from "@/components/Map.vue";
import {Component, Vue} from "vue-facing-decorator";

@Component({
  components: {
    SvgMap
  }
})
export default class App extends Vue {

  created() {

    console.log('TicketCafe APP created')
    //console.log('process.env=', process.env)

    if (!process.env.VUE_APP_API_URL)
      console.error('env.VUE_APP_API_URL not defined!')

  }

  mounted(){
    // Яндекс метрика
    const ym = {}
    for (const key of Object.keys(this.$route.query))
      if (key.includes('ym_'))
        ym[key.replace('ym_', '')] = this.$route.query[key]
    // todo не грузить яндекс метрику если она не используется (в index.html)
    if (Object.keys(ym).length) {
      window['ym'](ym['id'], "init", ym);
    }

  }

}

