
import {Component, Vue} from 'vue-facing-decorator';
import Map from "@/components/Map.vue";

@Component({
  components: {Map}
})
export default class HomeView extends Vue {

  eventId = ''

  mounted() {

    if (typeof this.$route.query['event'] === 'string') {

      this.eventId = this.$route.query['event'];
      //this.loadSVG()

    // }
    // else if (this.$route.path === '/inplace/letolife') {
    //
    //   this.eventId = '691'

    } else {
      console.error('No event in url')
    }

  }

}
